<template>
  <section class="box-height perfromanceTable" style="padding: 0 15px">
    <div class="thesaurus" style="background-color: #fff; padding-bottom: 30px">
      <el-col :span="24" style="padding: 15px 0 10px">
        <el-button style="margin-left: 15px" size="mini" @click="getNew('')"
          >新增同义词</el-button
        >
        <el-pagination
          :hide-on-single-page="page.total > 10 ? false : true"
          v-if="page.total > 0"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="page.current"
          :page-size="page.size"
          :pager-count="5"
          layout="total,prev, pager, next,sizes"
          :total="page.total"
          :page-sizes="[10, 20, 50, 100]"
        ></el-pagination>
      </el-col>
      <div
        class="pd40"
        style="background-color: #fff; padding-top: 10px; padding-bottom: 20px"
      >
        <el-table
          :data="ListData"
          v-loading="listLoading"
          :header-cell-style="{
            'background-color': '#f5f7fa',
            'border-top': '1px #e5e5e5 solid',
          }"
          class="tabBorder custor"
          style="width: 100%; margin: 0px; border-top: 0"
        >
          <el-table-column
            prop="labelTypeName"
            min-width="200"
            label="同义词组"
          >
            <template slot-scope="scope">
              <span
                class="mgr10 tag"
                v-for="(item, index) in scope.row.values"
                :key="index"
                >{{ item }}
              </span>
            </template>
          </el-table-column>
          <el-table-column prop="type" min-width="150" label="类型">
          </el-table-column>
          <el-table-column prop="creatorName" min-width="150" label="创建人">
          </el-table-column>
          <el-table-column prop="createdTime" label="创建时间" min-width="150">
          </el-table-column>
          <el-table-column fixed="right" label="操作" align="left" width="180">
            <template slot-scope="scope">
              <span
                class="tabHref"
                size="mini"
                @click="getNew(scope.row.synonymId)"
                >编辑
              </span>
              <el-divider direction="vertical"></el-divider>
              <span
                class="tabHref"
                size="mini"
                @click="deleteRow(scope.row.synonymId)"
                >删除</span
              >
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          :hide-on-single-page="page.total > 10 ? false : true"
          v-if="page.total > 0"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="page.current"
          :page-size="page.size"
          :pager-count="5"
          layout="total,prev, pager, next,sizes"
          :total="page.total"
          :page-sizes="[10, 20, 50, 100]"
          style="padding: 10px 15px 20px"
        ></el-pagination>
      </div>
    </div>
    <!-- 新增同义词 -->
    <el-dialog
      :visible.sync="dialogVisible"
      :modal-append-to-body="false"
      append-to-body
      class="matchDialog perfromanceTableDig"
      :title="dialogName"
      :close-on-click-modal="false"
      width="600px"
      :before-close="handleClose"
      destroy-on-close
    >
      <el-form
        @submit.native.prevent
        :model="createDate"
        ref="createDate"
        :rules="createDateRules"
        label-width="120px"
      >
        <el-row>
          <el-col :span="24">
            <el-form-item label="同义词组：" class="is-required" prop="values">
              <div class="inputContent">
                <el-tag
                  v-for="(x, xindex) in createDate.values"
                  :key="'tag' + xindex"
                  :title="x"
                  closable
                  type="info"
                  @close="delItem(xindex)"
                >
                  {{ x }}
                </el-tag>
                <el-input
                  v-model="values"
                  style="width: 350px; border: 0; margin: 8px 0 8px"
                  placeholder="多个同义词之间用空格隔开"
                  @input="getInpData"
                >
                </el-input>
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="类型：" prop="type">
              <el-select
                v-model="createDate.type"
                placeholder="请选择"
                filterable
                style="width: 240px"
              >
                <el-option
                  v-for="(item, index) in typesList"
                  :key="'a_' + index"
                  :value="item.value"
                  :label="item.name"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="footer-title clearfix">
        <span>
          <el-button type="default" size="mini" @click="handleClose"
            >取消</el-button
          >
          <el-button
            type="primary"
            size="mini"
            :loading="saveloading"
            @click="save('createDate')"
            >保存</el-button
          >
        </span>
      </div>
    </el-dialog>
  </section>
</template>

<script>
import {
  synonymPage, //分页展示同义词
  synonymdelete, //删除此同义词记录
  synonymDetail, //此同义词记录详情
  synonymAdd, //新增同义词记录
  synonymEdit, //编辑此同义词记录
  synonymValidate, //校验同义词是否重复
} from "../../api/api";
export default {
  data() {
    return {
      page: {
        total: 0,
        current: 1,
        size: 50,
      },
      listLoading: false,
      ListData: [],
      dialogVisible: false,
      createDate: { values: [], type: "职位名称" },
      saveloading: false,
      typesList: [
        {
          name: "职位名称",
          values: "职位名称",
        },
      ],
      createDateRules: {
        // values: [
        //   { required: true, trigger: "blur", message: "同义词组不能为空" },
        // ],
        type: [{ required: true, trigger: "change", message: "类型不能为空" }],
      },
      values: "",
      dialogName: "",
    };
  },
  created() {
    this.loadlist();
  },
  methods: {
    //pageSize 改变时会触发
    handleSizeChange(val) {
      this.page.size = val;
      this.page.current = 1;
      this.loadlist();
    },
    //currentPage 改变时会触发
    handleCurrentChange(val) {
      this.page.current = val;
      this.loadlist();
    },
    //获取列表数据
    loadlist() {
      window.scrollTo(0, 0);
      this.listLoading = true;
      synonymPage(this.page).then((res) => {
        if (res.success) {
          this.listLoading = false;
          this.ListData = res.result.records;
          this.page.total = res.result.total;
        }
        this.listLoading = false;
      });
    },
    //删除
    deleteRow(id) {
      this.$confirm("确认删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        customClass: "altCls",
        type: "warning",
      })
        .then(() => {
          synonymdelete({ synonymId: id }).then((res) => {
            if (res.success) {
              this.loadlist();
              this.$message.success("删除成功！");
            }
          });
        })
        .catch(() => {
          // this.$message({
          //   type: "info",
          //   message: "已取消删除",
          // });
        });
    },
    //新增、编辑
    getNew(id) {
      this.dialogVisible = true;
      if (id) {
        this.dialogName = "编辑同义词";
        this.getDetails(id);
      } else {
        this.dialogName = "新增同义词";
      }
    },
    //编辑回显
    getDetails(id) {
      synonymDetail({
        synonymId: id,
      }).then((res) => {
        if (res.success) {
          this.createDate = res.result;
        }
      });
    },
    //数据集数据处理
    getInpData() {
      if (
        this.values[this.values.length - 1] == " " &&
        this.values.replace(/(^\s*)|(\s*$)/g, "").length > 0
      ) {
        this.createDate.values.push(this.values.replace(/(^\s*)|(\s*$)/g, ""));
        this.values = "";
      }
    },
    //删除数据集
    delItem(xindex) {
      this.createDate.values.splice(xindex, 1);
    },
    //保存
    save(formName) {
      if (this.values.length > 0) {
        this.createDate.values.push(this.values);
        this.values = "";
      }
      if (this.values.length == 0 && this.createDate.values.length == 0) {
        this.$message.error("同义词组不能为空");
        return false;
      }
      return new Promise((resole) => {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.saveloading = true;
            let requsetName = null;
            if (this.createDate.synonymId) {
              requsetName = synonymEdit;
            } else {
              requsetName = synonymAdd;
            }
            synonymValidate(this.createDate).then((res) => {
              if (res.success) {
                if (res.result) {
                  let arr = null;
                  if (res.result.length > 1) {
                    arr = res.result.join("】【");
                    this.$message.error("【" + arr + "】同义词已存在");
                  } else {
                    arr = res.result[0];
                    this.$message.error("【" + arr + "】同义词已存在");
                  }
                  this.saveloading = false;
                } else {
                  requsetName(this.createDate).then((res1) => {
                    if (res1.success) {
                      //   this.$message.success("保存成功");
                      this.handleClose();
                      this.loadlist();
                    }
                    this.saveloading = false;
                  });
                }
              }
            });
          }
        });
      }).catch((error) => {});
    },
    //关闭弹窗
    handleClose() {
      this.dialogVisible = false;
      this.values = "";
      this.createDate = {
        values: [],
        type: "职位名称",
      };
    },
  },
};
</script>

<style lang="scss" scope>
.inputContent span {
  padding: 0 10px;
  max-width: auto;
  margin: 8px 0 8px 8px;
}
</style>

<style lang="scss" >
.perfromanceTableDig{
  .el-tag.el-tag--info{
    max-width: fit-content;
  }
}
</style>
